<template>
  <div>
    <main>
      <header v-if="fetched" :class="(showNav)?'visible':''">
        <img class="nav-logo" src="https://s3.adkins.coffee/assets/images/ma_portfolio_logo_light.svg" alt="Mark Adkins" />
        <div class="nav-container">
          <h1 class="nav-title">{{ response.orgName }} Portfolio</h1>
          <ul>
            <li v-for="(header, index) in headers" :key="'header-' + index" ref="section-header">{{header}}</li>
          </ul>
        </div>
      </header>
      <div>
        <img class="logo" src="https://s3.adkins.coffee/assets/images/ma_portfolio_logo_footer.svg" alt="Mark Adkins"/>
        <h1>Portfolio</h1>
        <h2 v-if="fetched">{{ response.orgName }}</h2>
        <div v-if="fetched" class="card">
          <h3 style="display: none;">Welcome</h3>
          <p>Hi there {{ response.orgName }} team!  Thank you for taking the time to check out my portfolio.</p>
          <p>I&apos;ve attached a few documents demonstrating my work.  Please reach out if you have any questions!</p>
          <div class="scheduling">
            <h4>Let's Chat</h4>
            <span class="mobile-only">Schedule&nbsp;An&nbsp;</span>
            <div class="selection">
              <span class="mobile-hidden">Schedule&nbsp;An&nbsp;</span>
              <button class="button" aria-label="Schedule an introduction" :data-cal-link="'meet/introduction?organization=' + response.orgName">Introduction</button>
              <span>or</span>
              <button class="button" aria-label="Schedule an interview" :data-cal-link="'meet/interview?organization=' + response.orgName">Interview</button>
            </div>
            <p>You can also contact me directly at: <a href="mailto:hello@markadkins.design">hello@markadkins.design</a>.</p>
          </div>
          <h3>Application Materials</h3>
          <a :href="cdnReplace(attachment.attributes.ReactiveResume)" v-for="(attachment, index) in response.resume" :key="'resume-' + index" target="_blank">
            <div class="callout-card-item other">
              <div class="item-content">
                  <h4>Resume</h4>
              </div>
            </div>
          </a>
          <a :href="cdnReplace(attachment.signed_url)" v-for="(attachment, index) in response.coverLetter" :key="'cover-' + index" target="_blank">
            <div class="callout-card-item other">
              <div class="item-content">
                <h4>Cover Letter</h4>
              </div>
            </div>
          </a>
          <h3 v-if="response.caseStudies.length > 0">Case Studies</h3>
          <a :href="cdnReplace(attachment.signed_url)" v-for="(attachment, index) in response.caseStudies" :key="'study-' + index" target="_blank">
            <div class="callout-card-item background" :data-index="index">
              <div class="item-content">
                <h4>{{ attachment.name }}</h4>
                <p v-if="attachment.password" class="access">Password to open:<br/>
                  <code v-on:click.prevent="">{{ attachment.password }}</code>
                  <button v-on:click.prevent="copyText(attachment.password, $event)">Copy</button>
                </p>
              </div>
              <figure class="featured-image">
                <img :src="attachment.featured_image" :alt="attachment.name + ' Featured Image'" />
              </figure>
            </div>
          </a>
          <h3 v-if="(response.additionalFiles.length > 0) || (response.links && response.links.length > 0)">Additional Files</h3>
          <div class="callout-card-item other" v-for="(link, index) in response.links" :key="'link-' + index">
            <div class="item-content link">
              <h4>{{ link.attributes.Title }}</h4>
              <p v-html="link.attributes.Description"></p>
              <span class="link-open">
                <a :href="link.attributes.URL" target="_blank" class="button" :aria-label="'Open ' + link.attributes.Title + ' in a new tab'">Open In New Tab</a>
              </span>
              <span class="link-source">
                <a v-if="link.attributes.SourceCodeURL && link.attributes.SourceCodeURL !== ''" :href="link.attributes.SourceCodeURL"  :aria-label="'Open source code for ' + link.attributes.Title + ' in a new tab'" target="_blank">View Source</a>
              </span>
            </div>
          </div>
          <a :href="cdnReplace(attachment.signed_url)" v-for="(attachment, index) in response.additionalFiles" :key="'file-' + index" target="_blank">
            <div class="callout-card-item other">
              <div class="item-content">
                <h4>{{ attachment.name }}</h4>
              </div>
            </div>
          </a>
          <h3 v-if="response.references.length > 0">References</h3>
          <div class="callout-card-item other" v-for="(reference, index) in response.references" :key="'ref-' + index">
            <div class="item-content">
              <h4>{{ reference.attributes.Name }}</h4>
              <h5>{{ reference.attributes.CurrentPosition }}, {{ reference.attributes.CurrentEmployer }}</h5>
              <div class="ref-relationship"><strong>{{ reference.attributes.ProfessionalReleationship }}</strong><span v-if="reference.attributes.company.data.attributes.company"> at {{ reference.attributes.company.data.attributes.company }}</span></div>
<!--              <div class="ref-description" v-html="reference.attributes.Description"></div>-->
              <div class="ref-contact">
                <div v-if="reference.attributes.PhoneNumber" class="phone">
                  <span>{{ reference.attributes.PhoneNumber | formatPhone }}</span>
                </div>
                <div v-if="reference.attributes.ContactEmail" class="email">
                  <a :href="'mailto:' + reference.attributes.ContactEmail" target="_blank">
                    <span>{{ reference.attributes.ContactEmail }}</span>
                  </a>
                </div>
                <div v-if="reference.attributes.LinkedInProfile" class="linkedin">
                  <a :href="reference.attributes.LinkedInProfile" target="_blank">
                    <span>{{ reference.attributes.LinkedInProfile | formatLinkedIn }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="error" class="card">
          <p>The link you are using is not valid.  Please check the URL and email me if you continue to have issues.  If you need to contact me, please send me an email at <a href="mailto:portfolio.help@markadkins.design">portfolio.help@markadkins.design</a>.</p>
        </div>
        <div v-if="!fetched && !error" class="card">
          <div class="loading">
            <div class="spinner"></div>
            <h4>Loading Portfolio Content...</h4>
          </div>
        </div>
      </div>
    </main>
    <footer class="main-container">
    <div class="row">
      <div class="col w-6">
        <img class="footer-logo" alt="Mark Adkins Portfolio Logo" src="https://s3.adkins.coffee/assets/images/ma_portfolio_logo_footer.svg"/>
        <div class="social-icons">
          <a href="https://linkedin.com/in/markadkn" target="_blank" rel="noreferrer"><img alt="Mark Adkins on LinkedIn" src="https://s3.adkins.coffee/assets/images/LinkedIn.svg"/></a>
          <a href="https://instagram.com/markadkins" target="_blank" rel="noreferrer"><img alt="Mark Adkins on Instagram" src="https://s3.adkins.coffee/assets/images/Instagram.svg"/></a>
          <a href="https://github.com/funkybunch" target="_blank" rel="noreferrer"><img alt="Mark Adkins on Github" src="https://s3.adkins.coffee/assets/images/Github.svg"/></a>
        </div>
        <div class="contact">
          <p>
            <span>202 </span>
            <span>999 </span>
            <span>3882</span>
          </p>
          <a href="mailto:hello@markadkins.design">hello@markadkins.design</a>
        </div>
      </div>
      <div class="col w-6">
        <div class="row footer-aside">
          <a href="https://services.acm.org/public/vcard/vcard.cfm?handle=markadkins" target="_blank" rel="noreferrer">
            <div class="col sigchi">
              <img alt="ACM Special Interest Group for Computer-Human Interaction (SIGCHI) Member" src="https://s3.adkins.coffee/assets/images/sigchi-inverted.svg"/>
            </div>
          </a>
          <a href="https://www.nngroup.com/ux-certification/verify/" target="_blank" rel="noreferrer">
            <div class="col nng">
              <img alt="Nielsen Norman Group UX Certified" src="https://s3.adkins.coffee/assets/images/nng-uxc-inverted.svg"/>
            </div>
          </a>
          <a href="https://uxpa.org/" target="_blank" rel="noreferrer">
            <div class="col uxpa">
              <img alt="UX Professional Association (UXPA) International Member" src="https://s3.adkins.coffee/assets/images/portfolio-PA_Member_Logo.svg"/>
            </div>
          </a>
        </div>
        <div class="row copyright">
          <div class="col w-12">
            <p>&copy;&nbsp;{{ new Date().getFullYear() }}&nbsp;Mark&nbsp;Adkins All&nbsp;Rights&nbsp;Reserved</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueGtm from '@gtm-support/vue2-gtm';
import debounce from 'lodash/debounce';
import './console';

Vue.use(VueGtm, {
  id: 'GTM-T937994',
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
});

library.add(faUserSecret);

Vue.component('font-awesome-icon', FontAwesomeIcon);
const pathArray = window.location.pathname.split('/');

export default {
  name: "app",
  data() {
    return {
      org: pathArray[1],
      key: pathArray[2],
      response: {
        orgName: "",
        resume: [],
        coverLetter: [],
        caseStudies: [],
        additionalFiles: [],
        references: [],
        links: []
      },
      headerObjs: [],
      headers: [],
      fetched: false,
      loaded: false,
      error: false,
      showNav: false,
      scrollPosition: 0,
    }
  },
  filters: {
    formatPhone: function(input) {
      let cleaned = ('' + input).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    },
    formatLinkedIn: function(input) {
      return input.replace("https://www.linkedin.com", "");
    }
  },
  methods: {
    getAttachmentData: function() {
      let self = this;
      let endpoint = 'https://api.madkins.dev/v2/portfolio/' + this.org + '?passcode=' + this.key + '&src=portfolio_share';
      axios
          .get(endpoint)
          .then(function(response) {
            self.response.orgName = response.data.Company.data.attributes.Name;
            self.response.resume = response.data.Resumes.data;
            self.response.caseStudies = response.data.case_studies;
            self.response.coverLetter = response.data.CoverLetter;
            self.response.additionalFiles = response.data.AdditionalFiles;
            self.response.references = response.data.References.data;
            self.response.links = response.data.links.data;
            self.fetched = true;
          })
          .catch(function(err) {
            self.error = true;
            console.log(err);
          });
    },
    copyText: function(text, event) {
      navigator.clipboard.writeText(text).then(function() {
        console.log('Copying to clipboard was successful!');
        event.target.innerText = "Copied!";
      }, function(err) {
        console.error('Could not copy text: ', err);
        event.target.innerText = "Failed to copy";
      });
    },
    emailify: function(text) {
      return text.replace(/\s+/g, '').replace('&','').toLowerCase();
    },
    cdnReplace: function(url) {
      return url.replace("https://mark-adkins-portfolio.nyc3.digitaloceanspaces.com/", "https://s3.adkins.coffee/assets/");
    },
    onScroll: function() {
      this.scrollPosition = Math.round(window.scrollY);
      this.showNav = (window.scrollY > 135);
      document.body.style.setProperty(
          "--scroll", (window.scrollY / (document.body.offsetHeight - window.innerHeight))
      );
      this.calcScrollValues();
    },
    calcScrollValues: function() {
      this.headerObjs.forEach((el, index) => {
        const elTop = el.getBoundingClientRect().top;

        if (elTop >= 200) {
          // Not Active
          this.$refs['section-header'][index].classList.remove("active");
        } else if (elTop <= 200) {
          // Active
          for(let header in this.$refs['section-header']) {
            this.$refs['section-header'][header].classList.remove("active");
          }
          this.$refs['section-header'][index].classList.add("active");
        }
      });
    }
  },
  mounted() {
    document.title = 'Portfolio // Mark Adkins';
    this.handleDebouncedScroll = debounce(this.onScroll, 15);
    window.addEventListener('scroll', this.handleDebouncedScroll);
    if(this.org === null && this.key === null) {
      // Ignore Request
      console.log("Org:", this.org);
      console.log("Key:", this.key);
      console.log(pathArray);
    } else {
      this.getAttachmentData();
    }
  },
  updated(){
    this.$nextTick(()=>{
      if(!this.loaded) {
        this.headerObjs = document.querySelectorAll('h3');
        this.headerObjs.forEach((el) => {
          this.headers.push(el.innerText);
        });
        this.loaded = true;
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
}
</script>

<style scoped>
@import url('https://markadkins.design/app.min.css');
@import 'style.css';
@import 'mobile.css';

* {
  font-family: 'Poppins', sans-serif;
}
html, body {
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}
body {
  display: flex;
  background-color: var(--background);
}
.mobile-only {
  display: none !important;
}
.mobile-hidden {
  display: block !important;
}
main {
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--background);
  padding-bottom: 60px;
  border-bottom: #ffffff 4px solid;
  color: #ffffff;
}
main p {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 16px;
}
main .mag > span {
  font-size: 24px;
  font-weight: bold;
}

</style>