import { render, staticRenderFns } from "./app.vue?vue&type=template&id=5fb00316&scoped=true"
import script from "./app.vue?vue&type=script&lang=js"
export * from "./app.vue?vue&type=script&lang=js"
import style0 from "./app.vue?vue&type=style&index=0&id=5fb00316&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb00316",
  null
  
)

export default component.exports